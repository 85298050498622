<template>
  <div>
    <section class="sign-in-page">
      <b-container
        fluid
      >
        <div
          class="row mt-5"
        >
          <div class="col-12 col-sm-4 offset-sm-4 align-self-center">
            <router-view />
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  name: 'RegisterLayout',

  data () {
    return {

    }
  },

  mounted () {
    core.index()
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");
</style>
